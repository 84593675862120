import type {App} from 'vue';
import type {Router} from 'vue-router';
import * as Sentry from '@sentry/vue';

export function initSentry(app: App, router: Router) {
  Sentry.init({
    app,
    dsn: 'https://9017332d5ad2673c371199c9ce64aaaa@o4505000982347776.ingest.us.sentry.io/4508721992957952',
    environment: import.meta.env.VITE_ENV ?? 'live',
    integrations: [Sentry.browserTracingIntegration({router}), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/*\.loc(:\d+)\//],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

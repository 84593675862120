import {getNow} from '@/helpers/date.ts';

const THRESHOLD = 60 * 30;

export function useTabReload() {
  let deactivated: undefined | Date = undefined;

  function handler() {
    if (!isVisible()) {
      onHide();
    } else if (deactivated) {
      onShow(deactivated);
    }
  }

  function onHide() {
    deactivated = getNow();
    console.log('HIDDEN', deactivated);
  }

  function onShow(deactivatedAt: Date) {
    const now = getNow();
    const diffSeconds = now.getTime() / 1000 - deactivatedAt.getTime() / 1000;
    if (diffSeconds > THRESHOLD) {
      location.reload();
    }
  }

  function isVisible() {
    return !document.hidden || document.visibilityState === 'visible';
  }

  function onAppMounted() {
    document.addEventListener('visibilitychange', handler);
  }
  function onAppUnmounted() {
    document.removeEventListener('visibilitychange', handler);
  }

  return {
    onAppMounted,
    onAppUnmounted,
  };
}

import type {PreachingDate, PreachingDateExact, PreachingDatePartial} from '@/types/fields';

const formats: Record<string, Intl.DateTimeFormatOptions> = {
  default: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  ym: {
    year: 'numeric',
    month: 'long',
  },
  y: {
    year: 'numeric',
  },
  ymdw: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'long',
  },
};
type Formats = keyof typeof formats;
const intl: Record<Formats, Intl.DateTimeFormat> = {};

function getIntl(format: Formats): Intl.DateTimeFormat {
  if (!intl[format]) {
    intl[format] = new Intl.DateTimeFormat('hu-CA', formats[format]);
  }
  return intl[format];
}

export function formatDate(date_str: string, format: string = 'default'): string {
  if (!date_str) {
    return '';
  }
  const date = new Date(date_str);
  return getIntl(format).format(date);
}

export function getNow() {
  return new Date();
}

export function isPartialDate(value: PreachingDatePartial) {
  if (value.type !== 'partial' || !value.partial?.year) {
    return false;
  }
  return value.partial?.year >= 1900 && value.partial?.year < 2100;
}

export function isExactDate(value: PreachingDateExact) {
  return (
    value.type === 'exact' &&
    /^((?:19|20)\d{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test('' + value.date)
  );
}

export function isDetailedDate(value: unknown) {
  if (typeof value === 'object' && value !== null) {
    return isExactDate(value as PreachingDateExact) || isPartialDate(value as PreachingDatePartial);
  }
  return false;
}

export function formatDetailedDate(value: PreachingDate): string {
  if (value.type === 'exact') {
    return formatDate(value.date);
  }

  return formatPartialDate(value);
}

export function formatPartialDate(value: PreachingDatePartial): string {
  const parts = [];
  let format = null;
  if (value.partial?.year) {
    parts.push('' + value.partial?.year);
    format = 'y';
    if (value.partial?.month) {
      parts.push(('' + value.partial?.month).padStart(2, '0'));
      parts.push('10');
      format = 'ym';
    }
  }
  if (format) {
    const date = new Date(parts.join('-'));
    return getIntl(format).format(date);
  }
  return '';
}

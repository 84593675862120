import {provide} from 'vue';
import {useHead} from 'unhead';
import {isEmbedded} from '@/helpers/embedded';

import type {Configuration} from '@/types/config';
import type {SiteItem} from '@/types/sites';

import ApiConnection from '@/api';

export async function initialFetch(params = {sites: true}) {
  if (isEmbedded()) {
    ApiConnection.rest.setEmbedded(true);
  }
  // Fetch token before everything else.
  await ApiConnection.fetchToken();
  // When we have token do the rest.
  const requests: Promise<unknown>[] = [ApiConnection.getConfig()];
  if (params.sites) {
    requests.push(ApiConnection.getSites());
  }
  const res = await Promise.all(requests);
  const config = res[0] as Configuration;
  if (isEmbedded()) {
    config.site.embedded_site = true;
  }

  let sites: SiteItem[] = [];
  if (params.sites) {
    sites = res[1] as SiteItem[];
  }

  return {
    config,
    sites,
  };
}

export function doProvide(config: Configuration, sites: SiteItem[]) {
  provide<Configuration>('LELKESZ.config', config);
  provide<boolean>('LELKESZ.isDefaultSite', config.site.default_site ?? false);
  provide<SiteItem[]>('LELKESZ.sites', sites);
}

export function beforeAppMount(config: Configuration) {
  const favicon = document.createElement('link');
  favicon.rel = 'icon';
  favicon.href = config.site.favicon?.url ?? '/favicon.ico';
  document.head.appendChild(favicon);

  useHead({
    htmlAttrs: {
      lang: 'hu',
    },
    meta: [
      {
        name: 'og:image',
        content: config.site.og_cover ?? location.origin + '/og_lelkesz.png',
      },
      {
        name: 'description',
        content: config.site.title,
      },
    ],
  });
}

export function appMounted() {
  document.getElementById('app')?.setAttribute('data-started', '1');
  document.getElementById('app--placeholder')?.remove();
}

import {createApp} from 'vue';
import {createHead} from '@vueuse/head';
import {createPinia} from 'pinia';
import router from '@/router';
import {initSentry} from '@/helpers/sentry.ts';
import LelkeszApp from '@/LelkeszApp.vue';
import {useTabReload} from '@/helpers/tab-reload.ts';
import {initMatomo} from '@/helpers/matomo.ts';

export function createLelkeszApp() {
  const lelkeszApp = createApp(LelkeszApp);

  const allowedEnvs = ['live', 'staging', 'demo'];
  if (allowedEnvs.includes(import.meta.env.VITE_ENV)) {
    initSentry(lelkeszApp, router);
    initMatomo(lelkeszApp, router);
  }

  lelkeszApp.use(createPinia());
  lelkeszApp.use(router);
  lelkeszApp.use(createHead());

  lelkeszApp.mount('#app');
  useTabReload().onAppMounted();

  return lelkeszApp;
}

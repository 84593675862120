import {ref} from 'vue';
import {defineStore} from 'pinia';

export const useAppThemeStore = defineStore('appTheme', () => {
  const zoom = ref(getStoredValue());

  function setZoom(value: number) {
    zoom.value = value;

    const clampedValue = Math.min(150, Math.max(100, value));
    if (typeof localStorage?.setItem === 'function') {
      localStorage?.setItem('predikacio_font_size', clampedValue.toString());
    }
  }

  function getStoredValue() {
    let storedValue = 100;
    if (typeof localStorage?.getItem === 'function') {
      storedValue = Number(localStorage?.getItem('predikacio_font_size') || 100);
    }
    return Math.min(150, Math.max(100, storedValue));
  }

  return {
    zoom,
    setZoom,
  };
});

<script setup lang="ts">
import type {MenuItem} from '@/types/config';
import {ref, onMounted, onBeforeUnmount} from 'vue';

import {
  createClasses,
  createLinkRel,
  getChildren,
  getNextDepth,
  hasChildren,
  itemClasses,
} from '@/helpers/menu';

defineProps<{
  classPrefix?: string;
  depth?: number;
  items: MenuItem[];
}>();

const rootElement = ref<HTMLElement | null>(null);

function getElementButton(el: HTMLElement): HTMLButtonElement | null {
  return el.querySelector('.header--menu--item--button');
}

function getElementSubmenu(el: HTMLElement): HTMLUListElement | null {
  return el.querySelector('.submenu');
}

function openSubmenu(el: HTMLElement) {
  const btn = getElementButton(el);
  if (btn) {
    btn.setAttribute('aria-expanded', 'true');
  }
  setSubmenuTabindex(el, '0');
}

function closeSubmenu(el: HTMLElement) {
  const btn = getElementButton(el);
  if (btn) {
    btn.setAttribute('aria-expanded', 'false');
  }
  setSubmenuTabindex(el, '-1');
}

function toggleSubmenu(el: HTMLElement, toState: boolean) {
  if (toState) {
    closeOtherSubmenus(el);
    openSubmenu(el);
  } else {
    closeSubmenu(el);
  }
}

function closeOtherSubmenus(current: HTMLElement) {
  const siblings = current.parentElement?.querySelectorAll('.menu__item--level-1');
  siblings?.forEach((el) => {
    if (el !== current) {
      closeSubmenu(el as HTMLElement);
    }
  });
}

function setSubmenuTabindex(el: HTMLElement, tabindex: string) {
  const submenu = getElementSubmenu(el);
  if (!submenu) return;
  submenu.querySelectorAll<HTMLAnchorElement>('.menu__link').forEach((link) => {
    link.setAttribute('tabindex', tabindex);
  });
}

function isDesktop(): boolean {
  const hamburger = document.querySelector('.header--menu--control');
  const rectangle = hamburger?.getBoundingClientRect();
  return !(!rectangle || (rectangle.width > 0 && rectangle.height > 0));
}

function initSubmenu(el: HTMLElement) {
  const button = getElementButton(el);
  if (!button) return;

  button.addEventListener('click', () => {
    toggleSubmenu(el, button.getAttribute('aria-expanded') !== 'true');
  });

  el.addEventListener('mouseover', () => {
    if (isDesktop()) {
      openSubmenu(el);
    }
  });

  el.addEventListener('mouseout', () => {
    if (isDesktop()) {
      closeSubmenu(el);
    }
  });

  button.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      closeSubmenu(el);
    }
  });
}

onMounted(() => {
  const menuItems = rootElement.value?.querySelectorAll('.header--menu--item-with-children');
  menuItems?.forEach((el) => initSubmenu(el as HTMLElement));
});

onBeforeUnmount(() => {
  const menuItems = rootElement.value?.querySelectorAll('.header--menu--item-with-children');
  menuItems?.forEach((el) => {
    const button = getElementButton(el as HTMLElement);
    button?.removeEventListener('click', () => toggleSubmenu(el as HTMLElement, false));
  });
});
</script>
<template>
  <ul
    v-if="items.length"
    :class="createClasses(['menu'], classPrefix)"
    :data-depth="depth || 0"
    ref="rootElement"
  >
    <li
      v-for="(menuItem, index) in items"
      :key="index"
      :class="itemClasses(menuItem, classPrefix)"
    >
      <a
        :href="menuItem.alias || menuItem.url"
        :class="createClasses(['menu--link'], classPrefix)"
        :rel="createLinkRel(menuItem)"
      >
        {{ menuItem.title }}
      </a>
      <button
        v-if="hasChildren(menuItem) && !depth"
        :class="createClasses(['menu--item--button'], classPrefix)"
        :aria-controls="`submenu-${index}`"
        :data-ariacontrols="`submenu-${index}`"
        aria-expanded="false"
      >
        Almenü megnyitása
      </button>
      <div
        :id="`submenu-${index}`"
        class="submenu--wrapper"
      >
        <MenuList
          v-if="hasChildren(menuItem) && !depth"
          :items="getChildren(menuItem)"
          :class-prefix="classPrefix"
          :depth="getNextDepth(depth)"
        />
      </div>
    </li>
  </ul>
</template>

import type {UrlString} from '@/types/rest';

export function isEmbedded() {
  const u = new URL(document.location.href);
  const val = u.searchParams.get('EMBEDDED') ?? 0;
  return parseInt(val.toString()) === 1;
}

export function redirectTo(url: UrlString) {
  if (window.location.href?.startsWith(url)) {
    return;
  }
  window.location.href = url;
}
